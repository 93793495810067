/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import { fetchOneDynamiReportPrams, fetchAllDynamicReportPrams, fetchAllDynamicReportDynamic } from '@/data/source/remote/api'
import Repository from '@/data/repository'

class ReportingDetailUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    return new Repository(fetchAllDynamicReportPrams(`?$filter=DynamicReportId eq ${filter.id}`), null).getResult(false)
  }

  getOne(id: any): Promise<Response> {
    return new Repository(fetchOneDynamiReportPrams(id), null).getResult(false)
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    return new Repository(fetchAllDynamicReportDynamic(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const reportingDetailUseCase = new ReportingDetailUseCase()

export {
  reportingDetailUseCase
}
/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllDiskon, editDiskon, addDiskon, deleteDiskon, changeIsActiveDiskon, fetchVoucherPram, addVoucherHd, fetchVoucherHd, fetchVoucherHdV2, uploadFileVoucherHd, changeIsActiveVoucherHd, uploadCustomPath, fetchOneVoucherHd, editVoucherHd, deleteVoucherHd, redeemSouvenirByGuid, claimVoucher, fetchHistoryRedeemVoucher, addVoucherHdV2, editVoucherHdV2, addUserVoucher, fetchAllMyVoucher, deleteUserVoucher
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import deliveryType from '@/data/source/local/json/deliveryType.json'
import { getListAgent } from '../trips/agent/agentUseCase'
import { priceUseCase } from './priceUseCase'
import { getListRute } from './route/ruteUseCase'
import { getAllItemsKategoriBarang } from '../items/itemCategoryUseCase'

class VoucherDiscountUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    let params = '?count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Kode), '${filter.search}') or Contains(tolower(Agen/Kode),'${filter.search}') or Contains(tolower(Agen/Nama),'${filter.search}')
        or Contains(tolower(Rute/KotaAsal/Kode),'${filter.search}') or Contains(tolower(Rute/KotaAsal/Nama),'${filter.search}')
        or Contains(tolower(Rute/KotaTujuan/Kode),'${filter.search}') or Contains(tolower(Rute/KotaTujuan/Nama),'${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy= ${filter.sorting}` : params
    }
    return new Repository(fetchAllDiskon(params), null).getResult(false)
  }

  async getDataForm(id: any = ''): Promise<any> {
    let message = ''
    const response = new Response()
    response.error = false
    const price = await priceUseCase.getAll({
      custom: 'isActive eq true',
      select: '&$select=id,kode'
    })
    if (price.error) {
      response.error = true
      message = 'Gagal mendapatkan data harga'
    }
    const route = await getListRute({
      expand: '&$expand=KotaAsal($select=Nama),KotaTujuan($select=Nama)',
      select: '&$select=Id,KotaAsalId,KotaTujuanId'
    })
    if (route.error) {
      response.error = true
      message = `${message}, rute`
    }

    const agent = await getListAgent({
      select: '&$select=Id,Kode,Nama'
    })
    if (route.error) {
      response.error = true
      message = `${message}, agent`
    }

    const categoryItems = await getAllItemsKategoriBarang({
      custom: 'isActive eq true ',
      select: '&$select=Id,Nama'
    })
    if (categoryItems.error) {
      response.error = true
      message = `${message}, kategori barang`
    }

    response.message = message
    response.result = {
      route: route.result.map((val: any) => ({
        Id: val.Id,
        Nama: `${val.KotaAsal.Nama} - ${val.KotaTujuan.Nama}`
      })),
      price: price.result,
      agent: agent.result,
      categoryItems: categoryItems.result,
      deliveryType
    }

    return response
  }

  submitData(id: any, data: any): Promise<Response> {
    if (id !== '') {
      data.Id = id

      return new Repository(editDiskon(id, data), null).getResult(false)
    }

    data.IsActive = true

    return new Repository(addDiskon(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteDiskon(id), null).getResult(false)
  }

  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveDiskon(data), null).getResult(false)
  }

  getVoucherPram(): Promise<Response> {
    return new Repository(fetchVoucherPram(), null).getResult(false)
  }

  submitVoucherPram(id: any, data: any): Promise<Response> {
    console.log('aku masukk')
    if (id) {
      return new Repository(editVoucherHdV2(Number(id), data), null).getResult(false)
    }
    return new Repository(addVoucherHdV2(data), null).getResult(false)
  }

  getVoucherHd(filter: any): Promise<Response> {
    console.log('filter', filter)
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Nama), '${filter.search}') or MsVoucherCodes/any(s: contains(s/Kode, '${filter.search}')))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy= ${filter.sorting}` : params
    }
    return new Repository(fetchVoucherHd(params), null).getResult(false)
  }

  getVoucherHdV2(filter: any): Promise<Response> {
    console.log('aaaaaaa')
    let params = '?'
    if (filter) {
      const arrayFilter = []
      if (filter.page) {
        arrayFilter.push(`PageNumber=${filter.page}`)
      }
      if (filter.top) {
        arrayFilter.push(`PageSize=${filter.top}`)
      }
      if (filter.generateRefCode) {
        arrayFilter.push(`generateRefCode=${filter.generateRefCode}`)
      }
      if (filter.type) {
        arrayFilter.push(`type=${filter.type}`)
      }
      if (arrayFilter.length > 0) {
        params = `${params}${arrayFilter.join('&')}`
      }
    }
    return new Repository(fetchVoucherHdV2(params), null).getResult(false)
  }

  submitFileVoucherHd(data: any): Promise<Response> {
    return new Repository(uploadCustomPath('voucher', data), null).getResult(false)
  }

  changeIsActiveVoucherHd(id: number, data: any): Promise<Response> {
    return new Repository(changeIsActiveVoucherHd(id, data), null).getResult(false)
  }

  deleteVoucherDiscount(id: number): Promise<Response> {
    return new Repository(deleteVoucherHd(id), null).getResult(false)
  }

  getSpecific(id: any, filter: any = ''): Promise<Response> {
    console.log('id', id)
    console.log('filter', filter)
    let params = '?$count=true'
    if (filter) {
      // if (filter.expand) {
      //   params = `${params}${filter.expand}`
      // }
      // if (filter.select) {
      //   params = `${params}${filter.select}`
      // }
      // const arrayFilter = []
      // if (filter.search) {
      //   arrayFilter.push(`(Contains(tolower(Kode), '${filter.search}') or Contains(tolower(Agen/Kode),'${filter.search}') or Contains(tolower(Agen/Nama),'${filter.search}')
      //   or Contains(tolower(Rute/KotaAsal/Kode),'${filter.search}') or Contains(tolower(Rute/KotaAsal/Nama),'${filter.search}')
      //   or Contains(tolower(Rute/KotaTujuan/Kode),'${filter.search}') or Contains(tolower(Rute/KotaTujuan/Nama),'${filter.search}'))`)
      // }
      // if (filter.custom) {
      //   arrayFilter.push(filter.custom)
      // }
      // if (arrayFilter.length > 0) {
      // }
      params = `${params}&$filter=(MsVoucherCodes/any(s: contains(s/Kode, '${filter.search}')))`
      // params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      // params = (filter.sorting) ? `${params}&$orderBy= ${filter.sorting}` : params
    }
    return new Repository(fetchOneVoucherHd(id), null).getResult(false)
  }

  redeemByGuid(guid: any = ''): Promise<any> {
    return new Repository(redeemSouvenirByGuid(guid), null).getResult(false)
  }

  claimVoucher(data: any): Promise<Response> {
    return new Repository(claimVoucher(data), null).getResult(false)
  }

  fetchHistoryRedeemVoucher(filter: any = ''): Promise<Response> {
    let params = '?'
    if (filter) {
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`name=${filter.search}`)
      }
      if (filter.orderby) {
        arrayFilter.push(`orderby=${filter.orderby}`)
      }
      if (filter.pageNumber) {
        arrayFilter.push(`PageNumber=${filter.pageNumber}`)
      }
      if (filter.pageSize) {
        arrayFilter.push(`PageSize=${filter.pageSize}`)
      }
      if (filter.voucherType) {
        arrayFilter.push(`voucherType=${filter.voucherType}`)
      }
      if (arrayFilter.length > 0) {
        params = `${params}${arrayFilter.join('&')}`
      }
    }
    return new Repository(fetchHistoryRedeemVoucher(params), null).getResult(false)
  }

  submitUserVoucher(data: any): Promise<Response> {
    return new Repository(addUserVoucher(data), null).getResult(false)
  }

  getUserVoucher(filter: any = ''): Promise<Response> {
    let params = '?'
    if (filter) {
      const arrayFilter = []
      if (filter.username) {
        arrayFilter.push(`userId=${filter.username}`)
      }
      if (filter.pageNumber) {
        arrayFilter.push(`PageNumber=${filter.pageNumber}`)
      }
      if (filter.pageSize) {
        arrayFilter.push(`PageSize=${filter.pageSize}`)
      }
      if (arrayFilter.length > 0) {
        params = `${params}${arrayFilter.join('&')}`
      }
    }
    return new Repository(fetchAllMyVoucher(params), null).getResult(false)
  }

  deleteUserVoucher(id: any): Promise<Response> {
    return new Repository(deleteUserVoucher(id), null).getResult(false)
  }
}

const voucherDiscountUseCase = new VoucherDiscountUseCase()

export {
  voucherDiscountUseCase
}

enum PermissionEnum {
  AllPermission = '*:*',
  AcknowledgeDeliveryCancelation = 'backend:AcknowledgeDeliveryCancelation',
  AddAgentDeliveryDraft = 'backend:AddAgentDeliveryDraft',
  ApproveDeliveryCancelation = 'backend:ApproveDeliveryCancelation',
  ApproveDeliveryReturn = 'backend:ApproveDeliveryReturn',
  CreateAgentType = 'backend:CreateAgentType',
  CreateCity = 'backend:CreateCity',
  CreateCityGroup = 'backend:CreateCityGroup',
  CreateGoodCategory = 'backend:CreateGoodCategory',
  CreateLabelDistribution = 'backend:CreateLabelDistribution',
  CreateLabelType = 'backend:CreateLabelType',
  CreatePaymentType = 'backend:CreatePaymentType',
  DeclineDeliveryCancelation = 'backend:DeclineDeliveryCancelation',
  DeclineDeliveryReturn = 'backend:DeclineDeliveryReturn',
  DeleteAgentType = 'backend:DeleteAgentType',
  DeleteGoodCategory = 'backend:DeleteGoodCategory',
  DeleteLabelDistribution = 'backend:DeleteLabelDistribution',
  DeleteLabelType = 'backend:DeleteLabelType',
  DeletePaymentType = 'backend:DeletePaymentType',
  GetAgentType = 'backend:GetAgentType',
  GetDelivery = 'backend:GetDelivery',
  GetDeliveryCancelation = 'backend:GetDeliveryCancelation',
  GetDeliveryReturn = 'backend:GetDeliveryReturn',
  GetLabelType = 'backend:GetLabelType',
  GetPaymentType = 'backend:GetPaymentType',
  ListAgentDeliveryDrafts = 'backend:ListAgentDeliveryDrafts',
  ListAgentLabelStocks = 'backend:ListAgentLabelStocks',
  ListAgentTypes = 'backend:ListAgentTypes',
  BListDeliveries = 'backend:ListDeliveries',
  ListLabelDistributions = 'backend:ListLabelDistributions',
  ListLabelTypes = 'backend:ListLabelTypes',
  ListPaymentTypes = 'backend:ListPaymentTypes',
  RequestDeliveryCancelation = 'backend:RequestDeliveryCancelation',
  RequestDeliveryReturn = 'backend:RequestDeliveryReturn',
  TransferLabelDistribution = 'backend:TransferLabelDistribution',
  UpdateAgentType = 'backend:UpdateAgentType',
  UpdateCity = 'backend:UpdateCity',
  UpdateGoodCategory = 'backend:UpdateGoodCategory',
  ListGoodCategory = 'backend:ListAllGoodCategories',
  UpdateLabelType = 'backend:UpdateLabelType',
  UpdatePaymentType = 'backend:UpdatePaymentType',
  ViewDashboard = 'backend:ViewDashboard',
  ViewDeliveryStatusLogs = 'backend:ViewDeliveryStatusLogs',
  CheckoutBasket = 'basket:CheckoutBasket',
  CreateDraft = 'basket:CreateDraft',
  DeleteDraft = 'basket:DeleteDraft',
  ListDrafts = 'basket:ListDrafts',
  ModifyBasket = 'basket:ModifyBasket',
  ApproveRefundDeposit = 'company:ApproveRefundDeposit',
  ListRefundDeposits = 'company:ListRefundDeposits',
  PayRefundDeposit = 'company:PayRefundDeposit',
  AssignPackagesToCourier = 'kurir:AssignPackagesToCourier',
  KListDeliveries = 'kurir:ListDeliveries',
  ListPickups = 'kurir:ListPickups',
  UploadFile = 'media:UploadFile',
  AddPermissionsToGroupUser = 'user:AddPermissionsToGroupUser',
  CreateGroupUser = 'user:CreateGroupUser',
  CreateUserAddress = 'user:CreateUserAddress',
  DeleteGroupUser = 'user:DeleteGroupUser',
  DeletePermissionFromGroupUser = 'user:DeletePermissionFromGroupUser',
  DeleteUser = 'user:DeleteUser',
  DeleteUserAddress = 'user:DeleteUserAddress',
  GetGroupUser = 'user:GetGroupUser',
  GetProfile = 'user:GetProfile',
  GetUser = 'user:GetUser',
  GetUserAddress = 'user:GetUserAddress',
  GetUserAddresses = 'user:GetUserAddresses',
  GetUserExternalLinks = 'user:GetUserExternalLinks',
  GetUserPoint = 'user:GetUserPoint',
  GetUserPointHistory = 'user:GetUserPointHistory',
  ListGroupUserLevels = 'user:ListGroupUserLevels',
  ListGroupUserPermissions = 'user:ListGroupUserPermissions',
  ListGroupUsers = 'user:ListGroupUsers',
  ListUsers = 'user:ListUsers',
  SetUserAddressAsPrimary = 'user:SetUserAddressAsPrimary',
  UpdateGroupUser = 'user:UpdateGroupUser',
  UpdateUser = 'user:UpdateUser',
  UpdateUserAddress = 'user:UpdateUserAddress',
  AssignVoucherToUser = 'voucher:AssignVoucherToUser',
  BuyVoucher = 'voucher:BuyVoucher',
  CreateVoucher = 'voucher:CreateVoucher',
  CreateVoucherClaim = 'voucher:CreateVoucherClaim',
  DeleteVoucher = 'voucher:DeleteVoucher',
  DeleteVoucherFromUser = 'voucher:DeleteVoucherFromUser',
  GetVoucherClaim = 'voucher:GetVoucherClaim',
  ListVoucherClaims = 'voucher:ListVoucherClaims',
  UpdateVoucher = 'voucher:UpdateVoucher',
  ShowDetLoadMan = 'backend:ShowLoadingManifestDetail',
  ShowTotDetMan = 'backend:ShowLoadingManifestIncome',
  ShowHisClaim = 'backend:ListClaimsHistory',
  ShowNom = 'backend:ShowBusScheduleIncome',
  AddHourSchedule = 'backend:ModifyCourierSchedules',
  Dashboard = 'kurir:ViewDashboard',
  NoFilPelRe = 'noFilPelRe:',
  FilKot = 'filKot:',
  FilGuid = 'filGuid:',
  ShowGuid = 'showGuid:',
  CanPrint = 'backend:PrintTrackingNumber',
  ShowAllManifest = 'backend:ListAllManifests',
  CanApvDeposit = 'company:ApproveRefundDeposit',
  CanPayDeposit = 'company:PayRefundDeposit',
  ListRoutes = 'backend:ListRoutes',
  GetRoute = 'backend:GetRoute',
  CreateRoute = 'backend:CreateRoute',
  ListAllMyVoucher = 'voucher:ListAllMyVoucher',
  ListPrices = 'backend:ListPrices',
  CreatePrice = 'backend:CreatePrice',
  UpdatePrice = 'backend:UpdatePrice',
  GetPriceDetail = 'backend:GetPriceDetail',
  ModifyPriceDetail = 'backend:ModifyPriceDetail',

  ShowCheckReceipt = 'backend:AcknowledgeDeliveryStatus',
  ShowListDelivery = 'backend:ListAllDelivery',
  ShowUsers = 'user:ListUsers',
  ShowTrackingCourier = 'kurir:ShowCurrentPositions',
  ShowCourierAttendance = 'kurir:ListAttendances',
  ConfirmCourierCommision = 'kurir:ConfirmCommission',
  PeriodCommissionPayout = 'kurir:CreateCommissionPayout',
  ManualCommissionPayout = 'kurir:CreateCommisionManualPayout',
  AdjustCommission = 'kurir:AdjustCommision',
  ViewDashboardCourier = 'kurir:ViewDashboard'
}

export default PermissionEnum

/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import dotenv from 'dotenv'

dotenv.config()

export default class Configuration {
  static get CONFIG(): any {
    return {
      API_BASE: '$VUE_APP_API_BASE',
      API_URL: '$VUE_APP_API_URL',
      API_REPORTING: '$VUE_APP_API_REPORTING',
      API_OPENTICKET: '$VUE_APP_API_OPENTICKET',
      API_COURIER: '$VUE_APP_API_COURIER',
      API_VOUCHER: '$VUE_APP_API_VOUCHER',
      // VERSION: '$VUE_APP_VERSION',
      VERSION: 'v2.8.0',
      GOOGLE_KEY: '$VUE_APP_GOOGLE_KEY',
      HYDRA_API: '$VUE_APP_HYDRA_API',
      HYDRA_CLIENT_ID: '$VUE_APP_HYDRA_CLIENT_ID',
      HYDRA_SCOPE: '$VUE_APP_HYDRA_SCOPE',
      HYDRA_RESPONSE_TYPE: '$VUE_APP_HYDRA_RESPONSE_TYPE',
      HYDRA_STATE: '$VUE_APP_HYDRA_STATE',
      HYDRA_CLIENT_SECRET: '$VUE_APP_HYDRA_CLIENT_SECRET',
      HYDRA_GRANT_TYPE: '$VUE_APP_HYDRA_GRANT_TYPE',
      URL_BASE_MQTT: '$VUE_APP_URL_BASE_MQTT',
      PORT_BASE_MQTT: '$VUE_APP_PORT_BASE_MQTT',
      USERNAME_MQTT: '$VUE_APP_USERNAME_MQTT',
      PASSWORD_MQTT: '$VUE_APP_PASSWORD_MQTT',
      PROTOCOL_BASE_MQTT: '$VUE_APP_PROTOCOL_BASE_MQTT',
      LINK_WA: '$VUE_APP_LINK_WA',
      API_COMPANY: '$VUE_APP_API_COMPANY',
      API_USER: '$VUE_APP_API_USER',
      API_USERV2: '$VUE_APP_API_USERV2',
      API_SETTING: '$VUE_APP_API_SETTING',
      API_VOUCHERV2: '$VUE_APP_API_VOUCHERV2',
      API_RECEIPT: '$VUE_APP_API_RECEIPT',
      API_IDENTITY: '$VUE_APP_API_IDENTITY',
      API_IDENTITYV2: '$VUE_APP_API_IDENTITYV2'
    }
  }

  static value(name: any) {
    if (!(name in this.CONFIG)) {
      console.log(`Configuration: There is no key named "${name}"`)
      return
    }

    const value = this.CONFIG[name]

    if (!value) {
      console.log(`Configuration: Value for "${name}" is not defined`)
      return
    }

    if (value.startsWith('$VUE_APP_')) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1)
      const envValue = process.env[envName]
      if (envValue) {
        return envValue
      } else {
        console.log(`Configuration: Environment variable "${envName}" is not defined`)
      }
    } else {
      // value was already replaced, it seems we are in production.
      return value
    }
  }
}

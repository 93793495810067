import type {
  ActionTree
} from 'vuex'
import { RootState } from '@/store/types'
import {
  TDeliveryItem
} from './types'

const actions: ActionTree<TDeliveryItem, RootState> = {
  setCurrentIndex: ({ commit }, currentIndex: any) => {
    commit('SET_CURRENT_INDEX', currentIndex)
  },
  setDefaultState: ({ commit }) => {
    commit('SET_DEFAULT_STATE')
  },
  setListAgent: ({ commit }, payload: any) => {
    commit('SET_LIST_AGENT', payload)
  },
  setListScheduleBus: ({ commit }, payload: any) => {
    commit('SET_LIST_SCHEDULE_BUS', payload)
  },
  setToAgentId: ({ commit }, toAagentId: any) => {
    commit('SET_TO_AGENT_ID', toAagentId)
  },
  toAgentName: ({ commit }, toAgentName: any) => {
    commit('SET_TO_AGENT_NAME', toAgentName)
  },
  setAddressToAgent: ({ commit }, address: any) => {
    commit('SET_ADDRESS_TO_AGENT', address)
  },
  setToCityId: ({ commit }, toCityId: any) => {
    commit('SET_TO_CITY_ID', toCityId)
  },
  setScheduleId: ({ commit }, scheduleId: any) => {
    commit('SET_SCHEDULE_ID', scheduleId)
  },
  setDeliveryHdId: ({ commit }, deliveryHdId: any) => {
    commit('SET_DELIVERY_HD_ID', deliveryHdId)
  },
  setDeliveryIsCompany: ({ commit }, deliveryIsCompany: any) => {
    commit('SET_DELIVERY_IS_COMANY', deliveryIsCompany)
  },
  setTypeDelivery: ({ commit }, typeDelivery: any) => {
    commit('SET_TYPE_DELIVERY', typeDelivery)
  },
  setListCategoryItems: ({ commit }, listCategoryItems: any) => {
    commit('SET_LIST_CATEGORY_ITEMS', listCategoryItems)
  },
  setListItem: ({ commit }, listItems: any) => {
    commit('SET_LIST_ITEMS', listItems)
  },
  pushListItems: ({ commit }, item: any) => {
    commit('PUSH_LIST_ITEMS', item)
  },
  setCompanyId: ({ commit }, companyId: any) => {
    commit('SET_COMPANY_ID', companyId)
  },
  setCustomerId: ({ commit }, customerId: any) => {
    commit('SET_CUSTOMER_ID', customerId)
  },
  setCompanyAddressId: ({ commit }, CompanyAddressId: any) => {
    commit('SET_COMPANY_ADDRESS_ID', CompanyAddressId)
  },
  setCompanyReceiveAddressId: ({ commit }, payload: any) => {
    commit('SET_COMPANY_RECEIVE_ADDRESS_ID', payload)
  },
  setDataSender: ({ commit }, CompanyAddressId: any) => {
    // console.log('action', CompanyAddressId)
    commit('SET_DATA_SENDER', CompanyAddressId)
  },
  setDataReceiver: ({ commit }, CompanyAddressId: any) => {
    commit('SET_DATA_RECEIVER', CompanyAddressId)
  },
  setDataAfterPost: ({ commit }, CompanyAddressId: any) => {
    commit('SET_DATA_AFTER_POST', CompanyAddressId)
  },
  setDataLastReceipt: ({ commit }, lastRecepitId: any) => {
    commit('SET_DATA_LAST_RECEIPT', lastRecepitId)
  },
  changeItemListItems: ({ commit }, item: any) => {
    commit('CHANGE_ITEM_LIST_ITEMS', item)
  },
  changePostionTabs: ({ commit }, position: any) => {
    commit('SET_POSTION_TABS', position)
  },
  setDefaultFormState: ({ commit }) => {
    commit('SET_DEFAULT_FORM_STATE')
  },
  changeItemListItemsFromIndex: ({ commit }, payload: any) => {
    commit('CHANGE_ITEM_LIST_ITEMS_FROM_INDEX', payload)
  },
  deleteItemListItems: ({ commit }, id: any) => {
    commit('DELETE_ITEM_LIST_ITEMS', id)
  },
  setVoucherId: ({ commit }, voucherId: any) => {
    commit('SET_VOUCHER_ID', voucherId)
  },
  setAttrVoucher: ({ commit }, attr: any) => {
    commit('SET_ATTRIBUTE_VOUCHER', attr)
  },
  setDefaultAttrVoucher: ({ commit }) => {
    commit('SET_DEFAULT_ATTRIBUTE_VOUCHER')
  },
  setPaymentId: ({ commit }, paymentId: any) => {
    commit('SET_PAYMENT_ID', paymentId)
  },
  setPaymentRef: ({ commit }, paymentRef: any) => {
    commit('SET_PAYMENT_REF', paymentRef)
  },
  setListDiskon: ({ commit }, listDiskon: any) => {
    commit('SET_LIST_DISKON', listDiskon)
  },
  calculateGrandPrice: ({ commit }) => {
    commit('CALCULATE_GRAND_PRICE')
  },
  setDefaultStateThreeFourStep: ({ commit }) => {
    commit('SET_LIST_ITEMS', [])
    commit('SET_VOUCHER_ID', null)
    commit('SET_PAYMENT_ID', null)
    commit('SET_PAYMENT_REF', null)
    commit('SET_LIST_DISKON', [])
    commit('CALCULATE_GRAND_PRICE')
  },
  calculateTotalDiskon: ({ commit }, diskon: any) => {
    commit('CALCULATE_TOTAL_DISKON', diskon)
  },
  setBiayaTambahan: ({ commit }, biayaTambahan: any) => {
    commit('SET_BIAYA_TAMBAHAN', biayaTambahan)
  },
  setPriceRecognition: ({ commit }, priceRecognition: any) => {
    commit('SET_PRICE_RECOGNITION', priceRecognition)
  },
  setCostInsurance: ({ commit }, costInsurance: any) => {
    commit('SET_COST_INSURANCE', costInsurance)
  },
  setDiskonPelanggan: ({ commit }, biaya: any) => {
    // console.log('biaya', biaya)
    commit('SET_DISKON_PELANGGAN', biaya)
  },
  setitemsLabel: ({ commit }, labels: any) => {
    commit('SET_ITEMS_LABEL', labels)
  },
  setItemsSticker: ({ commit }, itemsSticker: any) => {
    commit('SET_ITEMS_STICKER', itemsSticker)
  },
  pushItemsSticker: ({ commit }, data: any) => {
    commit('PUSH_ITEMS_STICKER', data)
  },
  setKeyTabsSchedule: ({ commit }, key: any) => {
    commit('SET_KEY_TABS_SCHEDULE', key)
  },
  setParamsTabsSchedule: ({ commit }, label: any) => {
    commit('SET_PARAMS_TABS_SCHEDULE', label)
  },
  setDescriptionAddOn: ({ commit }, desc: any) => {
    commit('SET_DESCRIPTION_ADDON', desc)
  },
  setAdditionalFeeDesc: ({ commit }, payload: any) => {
    commit('SET_DESCRIPTION_ADDITIONALFEE', payload)
  },
  setIsMiisi: ({ commit }, payload: boolean) => {
    commit('SET_ISMIISI', payload)
  }
}

export default actions

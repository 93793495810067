/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllCompany, fetchOneCompany, addCompany, editCompany, deleteCompany, changeIsActiveCompany
} from '@/data/source/remote/api'

class CompanyUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    return new Repository(changeIsActiveCompany(data), null).getResult(false)
  }

  getAll(filter: any = null): Promise<Response> {
    return new Repository(fetchAllCompany(`?$count=true&$top=${filter.top}&$skip=${filter.skip}&$filter=(Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Alamat), '${filter.search}') or Contains(tolower(NamaPic), '${filter.search}'))`), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    if (id !== undefined) {
      const response = await new Repository(fetchOneCompany(id, '?$expand=Kota($select=Nama,Kode,Id)'), null).getResult(false)
      return {
        title: 'Edit Pelanggan Perusahaan',
        data: response
      }
    }

    return {
      title: 'Tambah Pelanggan Perusahaan',
      data: null
    }
  }

  submitData(id: any, data: any): Promise<Response> {
    console.log('idnya use case', id)
    if (id) {
      data.Id = Number(id)
      return new Repository(editCompany(id, data), null).getResult(false)
    } return new Repository(addCompany(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    return new Repository(deleteCompany(id), null).getResult(false)
  }
}

const customerCompanyUseCase = new CompanyUseCase()

export {
  customerCompanyUseCase
}

/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ICrud from '@/domain/usecase/iCrud'
import { fetchAllReportAuthenGroup, addReportAuthenGroup } from '@/data/source/remote/api'
import Repository from '@/data/repository'

class ReportSettingUserGroupUseCase implements ICrud {
  getAll(filter: any = ''): Promise<Response> {
    console.log(`?$filter=DynamicReportId eq ${filter.id} and Aktif eq true&$expand=Group($select=id, groupuser)&$select=Id, DynamicReportId`)
    return new Repository(fetchAllReportAuthenGroup(`?$filter=DynamicReportId eq ${filter.id} and Aktif eq true&$expand=Group($select=id, groupuser)&$select=Id, DynamicReportId`), null).getResult(false)
  }

  getOne(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(data: any): Promise<Response> {
    return new Repository(addReportAuthenGroup(data), null).getResult(false)
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }
}

const reportSettingUserGroupUseCase = new ReportSettingUserGroupUseCase()

export {
  reportSettingUserGroupUseCase
}
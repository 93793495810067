import {
  fetchKota,
  fetchOneKota,
  addKota,
  editKota,
  deleteKota,
  fetchAllPulau,
  fetchAllGroupKota,
  addGroupKota,
  editGroupKota
} from '@/data/source/remote/api'
import Repository from '@/data/repository'

// const getAllKota = (filter: any) => new Repository(fetchKota(filter), null).getResult(false)
const getAllKota = (filter: any) => {
  let params = '?'
  if (filter) {
    params += `pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`
    if (filter.search) {
      params += `&query=${filter.search}`
    }
  }
  return new Repository(fetchKota(params), null).getResult(false)
}

const getPulau = () => new Repository(fetchAllPulau(), null).getResult(false)

const submitNewKota = async (data: any) => {
  let response
  if (data.Id) {
    response = await new Repository(editKota(data.Id, data), null).getResult(false)
  } else {
    response = await new Repository(addKota(data), null).getResult(false)
  }
  return response
}

const dataEditKota = (id: any) => new Repository(fetchOneKota(id), null).getResult(false)

const deleteDataKota = (id: any) => new Repository(deleteKota(id), null).getResult(false)

const getGroupKota = (filter: any = {}) => {
  let params = '?$count=true'
  if (filter) {
    if (filter.expand) {
      params = `${params}${filter.expand}`
    }
    if (filter.select) {
      params = `${params}${filter.select}`
    }

    const arrayFilter = []
    if (filter.search) {
      arrayFilter.push(`(Contains(tolower(Nama), '${filter.search}'))`)
    }
    if (filter.custom) {
      arrayFilter.push(filter.custom)
    }
    if (filter.filter) {
      arrayFilter.push(filter.filter)
    }
    if (arrayFilter.length > 0) {
      params = `${params}&$filter=${arrayFilter.join(' and ')}`
    }
    params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
  }
  return new Repository(fetchAllGroupKota(params), null).getResult(false)
}

const submitNewGroupKota = async (id: any, data: any) => {
  console.log('ind', id, data)
  let response
  if (id) {
    data.Id = Number(id)
    response = await new Repository(editGroupKota(data.Id, data), null).getResult(false)
  } else {
    response = await new Repository(addGroupKota(data), null).getResult(false)
  }
  return response
}

export {
  getAllKota,
  submitNewKota,
  dataEditKota,
  deleteDataKota,
  getPulau,
  getGroupKota,
  submitNewGroupKota
}
